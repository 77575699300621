/**
 * @file Redux action types and creators
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import * as t from '../types';
import { ActionParams, CombinedState } from '../types';

/**
 * update action
 * @param {string} type - type of action
 * @param {object} payload - payload of action
 * @returns {object} the update object
 */
export const update = ({ type, payload }: ActionParams): ActionParams => {
    return { type, payload };
};

/**
 * reset action
 * @param {string} type - the type to reset
 * @returns {object} the reset object
 */
export const reset = ({ type }: ActionParams): ActionParams => {
    return { type, payload: {} };
};

/**
 * gets the full combined reducers from redux
 * @param {CombinedState} state - the combined state
 * @returns {CombinedState} the combined state
 */
export function getCombinedState(state: CombinedState): CombinedState {
    return state;
}

// User Info related actions
/**
 * updates user information
 * @param {string} name - the user name
 * @param {string} email - the user email
 * @returns {object} the saga action
 */
export const setUserInformation = (name: string, email: string): ActionParams => ({
    type: t.CURRENT_USER_UPDATE,
    payload: { name: name, email: email },
});
export const clearUserInformation = (): ActionParams => ({ type: t.CURRENT_USER_RESET, payload: {} });

// Authentication related actions
export const setAuthenticationInitialized = (): ActionParams => ({ type: t.AUTHENTICATION_INIT, payload: {} });
export const setAuthenticationNotInitialized = (): ActionParams => ({ type: t.AUTHENTICATION_INIT, payload: {} });

/**
 * sets the access roles for a user
 * @param {any[]} roles - array of roles
 * @returns {object} the saga action
 */
export const setAuthenticationRealmAccessRoles = (roles: string[]): ActionParams => ({
    type: t.AUTHENTICATION_UPDATE,
    payload: { realmAccessRoles: roles },
});

// Auth Middleware Actions
/**
 * signs a user onto the app
 * @param {string} redirectUri - the uri to redirect to
 * @param {string} locale - the locale code
 * @returns {object} the saga action
 */
export const signOn = (redirectUri: string, locale: string): ActionParams => ({
    type: t.AUTHENTICATION_SIGN_ON,
    payload: {
        redirectUri: redirectUri,
        locale: locale,
    },
});

/**
 * signs a user off the app
 * @param {string} redirectUri - the uri to redirect to
 * @returns {object} the saga action
 */
export const signOff = (redirectUri: string): ActionParams => ({
    type: t.AUTHENTICATION_SIGN_OFF,
    payload: { redirectUri: redirectUri },
});

// App Launchers Actions
export const setAppLaunchersConfig = (config: unknown): ActionParams => ({
    type: t.APP_LAUNCHERS_UPDATE,
    payload: { config: config },
});
