/**
 * @file redux authentication sagas
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import { put, select } from 'redux-saga/effects';
import * as t from '../../types';
import { ActionParams } from '../../types';
import { getCombinedState } from '../../actions';
import Logger from 'abb-webcore-logger/Logger';
import Keycloak from 'keycloak-js';
import * as Settings from '../../../Settings';
import KeycloakSettings from 'webcore-common/KeycloakSettings';
import * as actions from '../../actions';
import * as Locale from '../../../locales/LocaleUtils';
import { getToken } from '../app';

/**
 * Authentication saga handles anything related to login in/out of the app
 * @param {ActionParams} action the action to eval
 */
export const authenticationSaga = function* (action: ActionParams) {
    switch (action.type) {
        case t.AUTHENTICATION_INITIALIZE_KEYCLOAK:
            yield authInitializeKeycloak();
            break;

        case t.AUTHENTICATION_SIGN_ON:
            yield signOnAuthenticationSaga(action);
            break;

        case t.AUTHENTICATION_SIGN_OFF:
            yield signOffAuthenticationSaga(action);
            break;

        default:
    }
};

/**
 * sign on saga
 * @param {ActionParams.payload} payload - the login payload
 */
export const signOnAuthenticationSaga = function* ({ payload }: ActionParams) {
    const { authentication } = yield select(getCombinedState);

    if (!authentication.keycloak) {
        yield authInitializeKeycloak();
    }

    try {
        yield authSignOn(payload.redirectUri, payload.locale);

        yield authSuccess(getToken);
    } catch (e) {
        Logger.error('signOnAuthenticationSaga ERR', e);
    }
};

/**
 * sign off saga
 * @param {ActionParams.payload} payload - the sign off payload
 */
export const signOffAuthenticationSaga = function* ({ payload }: ActionParams) {
    const { authentication } = yield select(getCombinedState);

    if (!authentication.keycloak) {
        yield authInitializeKeycloak();
    }

    try {
        yield authSignOff(payload.redirectUri);
    } catch (e) {
        Logger.error('signOffAuthenticationSaga ERR', e);
    }
};

/**
 * Initialize keycloak saga
 */
export const authInitializeKeycloak = function* () {
    let url = undefined;

    if (window.isDevelopmentEnvironment) {
        url = Settings.getEamxHost() + '/landing';
    }

    try {
        const keycloakSettingsObj = yield KeycloakSettings.load(url);
        const keycloak = yield Keycloak(keycloakSettingsObj);

        yield keycloak.init({
            onLoad: 'check-sso',
            refreshToken: localStorage.getItem('refreshToken') as string,
            token: localStorage.getItem('token') as string,
            idToken: localStorage.getItem('idToken') as string,
        });

        yield put({ type: t.AUTHENTICATION_UPDATE, payload: { keycloak: keycloak, isInitialized: true } });
    } catch (e) {
        Logger.error('authInitializeKeycloak ERR', e);
    }
};

/**
 * logs on to keycloak
 * @param {string} redirectUri - the uri to redirect to
 * @param {string} locale - the locale code
 */
export const authSignOn = function* (redirectUri: string, locale: string) {
    const { authentication } = yield select(getCombinedState);

    if (!authentication.keycloak.authenticated) {
        authentication.keycloak.onAuthError = (error: Error) => {
            Logger.error('keycloak onAuth error:', error);
        };

        yield authentication.keycloak.login({
            redirectUri: redirectUri,
            kcLocale: locale,
        });
    }
};

/**
 * runs on a successful auth attempt
 * @param {function} getToken - the get token function
 */
export const authSuccess = function* (getToken: Function) {
    const { authentication } = yield select(getCombinedState);

    try {
        // load user profile
        const userProfile = yield authentication.keycloak.loadUserProfile().catch((error: Error) => {
            Logger.error('Unable to get the User Information due to an error', error);
        });

        // We only set the authenticated user id, we don't use 'accounts' in the Insights sense
        // See: https://docs.microsoft.com/en-us/azure/azure-monitor/app/api-custom-events-metrics#authenticated-users
        yield Logger.setAuthenticatedUserContext(userProfile.email);

        yield put({
            type: t.CURRENT_USER_UPDATE,
            payload: {
                name: `${userProfile.firstName} ${userProfile.lastName}`,
                email: userProfile.email,
                initials: `${userProfile.firstName[0]}${userProfile.lastName[0]}`,
            },
        });

        // configure i18next
        yield Settings.loadBrowserWebSettings();
        yield Locale.setupLocales(getToken(authentication));

        // mark user as signed on
        yield put({
            type: t.AUTHENTICATION_UPDATE,
            payload: { realmAccessRoles: authentication.keycloak.realmAccess.roles, isUserSignedOn: true },
        });
    } catch (e) {
        Logger.error('authSuccess ERR', e);
    }
};

/**
 * signs the logged in uer out
 * @param {string} redirectUri - the uri to redirect to after sign off
 */
export const authSignOff = function* (redirectUri: string) {
    const { authentication } = yield select(getCombinedState);

    const logoutConfig = {
        redirectUri: redirectUri,
    };

    yield put({ type: t.AUTHENTICATION_UPDATE, payload: { isUserSignedOn: false } });
    yield put(actions.setAuthenticationNotInitialized());
    yield put(actions.clearUserInformation());

    yield Logger.clearAuthenticatedUserContext();

    authentication.keycloak.logout(logoutConfig);
};

/**
 * places keycloak token into local storage
 */
export const clearTokensInLocalStorage = function () {
    localStorage.removeItem('keycloak-cache-token');
    localStorage.removeItem('keycloak-cache-refreshToken');
    localStorage.removeItem('keycloak-cache-idToken');
};

/**
 * places keycloak token into local storage
 */
export const updateTokensInLocalStorage = function* () {
    const { authentication } = yield select(getCombinedState);

    localStorage.setItem('keycloak-cache-token', authentication.keycloak.token);
    localStorage.setItem('keycloak-cache-refreshToken', authentication.keycloak.refreshToken);
    localStorage.setItem('keycloak-cache-idToken', authentication.keycloak.idToken);
};
