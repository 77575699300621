/**
 * @file Contains functions for sanitizing state and action objects so that it can be serialized
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import { ActionParams, CombinedState } from './redux/types';

/**
 * Sanitize the state so that it can be serialized
 *
 * Any large data sets should be removed in here, there's not enough room in insights to log them over and over.
 *
 * Note: This affects only the previousState and nextState logs, the action where the value is set logs unless listed
 * as skipped in the actionSanitizer function below.
 *
 * @param {object} state - Redux state
 * @return {object} sanitized state
 */
function stateSanitizer(state: CombinedState): CombinedState {
    return Object.assign({}, state); // make a copy
}

/**
 * Sanitize the action so that it can be serialized
 * @param {object} action - Redux action
 * @return {object} sanitized action
 */
function actionSanitizer(action: ActionParams): ActionParams {
    return Object.assign({}, action); // make a copy
}

export { stateSanitizer, actionSanitizer };
