/**
 * @file Route for logging into the application
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import React, { Dispatch } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import { commonNamespace } from '../locales/constants';
import { signOn } from '../redux/actions';
import Logger from 'abb-webcore-logger/Logger';
import { Dialog, Branding } from 'webcore-ux/nextgen/components';
import ThemeProvider from 'webcore-ux/nextgen/theme/ThemeProvider';
import { ActionParams, CombinedState } from '../redux/types';

type LoginRouterProps = {
    performSignOn: (redirectUri: string) => void;
    isUserSignedOn: boolean;
    location: {
        state: {
            from: {
                pathname: string;
            };
        };
    };
    realmAccessRoles: string[];
};

/**
 * This is the Login Router, it redirects to the Login screen, which then redirect back to the app.
 */
export class LoginRouter extends React.Component<LoginRouterProps> {
    static propTypes = {};

    componentDidMount() {
        const redirectUri = `${window.location.protocol}//${window.location.host}/landing/`;

        Logger.info('Component did mount, about to sign on');

        setTimeout(() => {
            this.props.performSignOn(redirectUri);
        }, 250);
    }

    componentDidUpdate(prevProps: LoginRouterProps) {
        if (prevProps.isUserSignedOn !== this.props.isUserSignedOn) {
            const redirectUri = `${window.location.protocol}//${window.location.host}/landing/`;

            Logger.info('Component did update, about to sign on');

            setTimeout(() => {
                this.props.performSignOn(redirectUri);
            }, 250);
        }
    }

    shouldComponentUpdate(nextProps: LoginRouterProps) {
        return nextProps.isUserSignedOn;
    }

    render(): JSX.Element {
        const from = this.props.location.state || { from: { pathname: '/' } };

        if (!this.props.isUserSignedOn) {
            return <p />;
        } else {
            if (this.isUserAllowedInApp()) {
                return (
                    <Redirect
                        to={{
                            pathname: from.from.pathname,
                        }}
                    />
                );
            } else {
                return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <ThemeProvider>
                        <div className={'abbes-landing-invalid-permissions'}>
                            <Dialog
                                open={true}
                                showCloseButton={false}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                disablePortal={true}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                title={
                                    <div>
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/* @ts-ignore */}
                                        <Branding />
                                        <p>{i18next.t(`${commonNamespace}:noAppAccessError.title`)}</p>
                                    </div>
                                }
                            >
                                {i18next.t(`${commonNamespace}:noAppAccessError.dialogText`)}
                            </Dialog>
                        </div>
                    </ThemeProvider>
                );
            }
        }
    }

    /**
     * Determines if the user is allowed to access the application
     *
     * @returns {boolean} - Indicates if the user has permissions to access the app
     */

    isUserAllowedInApp() {
        const REQUIRED_USER_ROLE = 'user';
        const hasUserRole = this.props.realmAccessRoles.findIndex((role: string) => role.toLowerCase() === REQUIRED_USER_ROLE) >= 0;

        if (!hasUserRole) {
            Logger.warn(`User is missing the required realm access role '${REQUIRED_USER_ROLE}' to access this app.`);
        }

        return hasUserRole;
    }
}

LoginRouter.propTypes = {
    performSignOn: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    isUserSignedOn: PropTypes.bool.isRequired,
    realmAccessRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// Map Redux store state to props for this component
const mapStateToProps = (state: CombinedState) => {
    return {
        isUserSignedOn: state.authentication.isUserSignedOn,
        realmAccessRoles: state.authentication.realmAccessRoles,
    };
};

// Map props for this component to dispatch actions for the Redux store
const mapDispatchToProps = (dispatch: Dispatch<ActionParams>) => {
    return {
        /**
         * Dispatch an action to perform a Log In
         * @param {string} redirectUri - The redirect URI for a successful log in
         */
        performSignOn: (redirectUri: string) => {
            dispatch(signOn(redirectUri, navigator.language));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginRouter);
