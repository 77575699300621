/**
 * @file redux current-user reducer
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import * as t from '../../types';
import { ActionParams, CurrentUserState } from '../../types';

/**
 * the current user state
 * @returns {object} the initalized state
 */
export const State = (): CurrentUserState => ({
    name: '',
    email: '',
    initials: '',
});

/**
 * the current user reducer
 * @param {CurrentUserState} state - the current user state
 * @param {ActionParams.type} type - the action type
 * @param {ActionParams.payload} payload - the action payload
 * @returns {CurrentUserState} the state
 */
export const currentUser = (state = State(), { type, payload }: ActionParams): CurrentUserState => {
    switch (type) {
        case t.CURRENT_USER_UPDATE:
            return { ...state, ...payload };
        case t.CURRENT_USER_RESET:
            return State();
        default:
            return state;
    }
};
