/**
 * @file Landing container
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { LandingPage } from '../../components';
import { makeStyles } from '@material-ui/core/styles';

// JSS styling for this component
const useStyles = makeStyles((theme) => ({
    root: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        minHeight: `calc(100vh - ${theme.layout.appBar.height}px)`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        maxHeight: `calc(100vh - ${theme.layout.appBar.height}px)`,
        overflow: 'auto',
    },
}));

type LandingContainerProps = {
    className: string;
};

/**
 * Landing container
 * @param {object} props - Props for the Container
 * @returns {React.Component} - The React component to return
 */
export function LandingContainer(props: LandingContainerProps): JSX.Element {
    const { className } = props;

    const classes = useStyles();

    return (
        <div className={classes.root} data-testid={'abbes-landing-container-root'}>
            <LandingPage className={className} />
        </div>
    );
}

LandingContainer.propTypes = {
    className: PropTypes.string,
};

export default LandingContainer;
