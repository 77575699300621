/**
 * @file redux app sagas
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import { put, select } from 'redux-saga/effects';
import * as t from '../../types';
import { ActionParams } from '../../types';
import * as Locale from '../../../locales/LocaleUtils';
import { loadConfiguration } from '../../../Config';
import * as Settings from '../../../Settings';
import { getCombinedState } from '../../actions';

import { initializeApplicationInsights, isApplicationInsightsFeatureEnabled, initializeBreadcrumb } from 'webcore-common';
import FeatureFlags from 'webcore-common/FeatureFlags';
import Logger from 'abb-webcore-logger/Logger';

const TOKEN_EXPIRED = 'Token has expired. User will be prompted to login.';
const TOKEN_REFRESH_FAILED = 'Failed to refresh the token.'; // This scenario could be we're offline etc.  Token may or may not be valid, we have to wait until keycloak can determine.
const minTokenValidity = 20000; // the minimum time a token is valid

/**
 * gets a token from keycloak
 * @param authentication - the authentication reducer
 */

export const getToken = (authentication: any) => () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
        await authentication.keycloak.updateToken(minTokenValidity).catch(() => {
            if (!authentication.keycloak.token) {
                reject(new Error(TOKEN_EXPIRED));
            } else {
                reject(new Error(TOKEN_REFRESH_FAILED));
            }
        });

        resolve(authentication.keycloak.token);
    });

/**
 * App saga handles everything regarding app initialization
 * @param {ActionParams} action the action to evaluate
 */

export const appSaga = function* (action: ActionParams) {
    switch (action.type) {
        case t.APP_INIT:
            yield appInitSaga();
            break;
        default:
    }
};

/**
 * init app saga
 */

export const appInitSaga = function* () {
    const { authentication } = yield select(getCombinedState);

    // If the token is blank, that means we need to re-authenticate
    // we automatically do this here, since the layers don't have the logic/knowledge to do this themselves.
    if (!authentication.keycloak.token) {
        yield put({
            type: t.AUTHENTICATION_SIGN_ON,
            payload: { redirectUri: window.location.href, locale: navigator.language },
        });
    }

    try {
        //DEBUG: Use the following line to insert feature flags in dev environment. Must include "DEV_FEATURE_FLAGS".
        //yield FeatureFlags.load("/landing", "", () => new Promise(resolve => resolve('{"features": ["DEV_FEATURE_FLAGS", "stayOnSingleApp"]}')));
        //yield FeatureFlags.load("/landing", "", () => new Promise(resolve => resolve('{"features": ["DEV_FEATURE_FLAGS", "useAzureApplicationInsights"]}')));
        yield FeatureFlags.load('/landing');

        yield initializeApplicationInsights(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Settings.getAppInsightsKey(),
            'DigitalEnterpriseLandingPage',
            Settings.getVersion(),
            Settings.getEamxHost()
        );

        // application insights will not initialize if isApplicationInsightsFeatureEnabled is false. In this case, breadcrumb is initiated
        if (yield !isApplicationInsightsFeatureEnabled()) {
            yield initializeBreadcrumb(['/auth/']);
        }

        // load backend locales
        yield Locale.loadBackendLocales();

        // set app to initialized
        yield put({ type: t.APP_UPDATE, payload: { initializeState: t.APP_INIT_COMPLETE } });
    } catch (error) {
        Logger.info('initAuthenticationSaga ERR', error);
        yield put({ type: t.APP_UPDATE, payload: { initializeState: t.APP_INIT_ERROR } });
    }
};
