/**
 * @file redux app reducer
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import * as t from '../../types';
import { ActionParams, AppState } from '../../types';

/**
 * the app state
 * @returns {object} the initialized state
 */
export const State = (): AppState => ({
    initializeState: t.APP_INIT_START,
    initializeError: undefined,
});

/**
 * the app reducer
 * @param {AppState} state - the current user state
 * @param {ActionParams.type} type - the action type
 * @param {ActionParams.payload} payload - the action payload
 * @returns {AppState} the current state
 */
export const app = (state = State(), { type, payload }: ActionParams): AppState => {
    switch (type) {
        case t.APP_UPDATE:
            return { ...state, ...payload };
        case t.APP_RESET:
            return State();
        default:
            return state;
    }
};
