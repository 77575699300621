/**
 * @file Contains function that loads settings
 * @copyright © Copyright 2021 Hitachi ABB Power Grids.. All rights reserved.
 */

import { send } from 'webcore-common/Send';

/**
 * Requests the settings from the URL (either current or the specified host) and returns a promise.
 *
 * @param {string} [host] - The URL of the host that contains the settings.json
 * @return {Promise} - Promise resolving to the response, or rejecting on error.
 */
export default function loadSettings(host?: string): Promise<unknown> {
    let url = 'settings.json';

    if (host) {
        url = `${host}/landing/settings.json`;
    }

    return send('GET', url, {}, false as never);
}
