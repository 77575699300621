/**
 * @file redux sagas index
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import { takeLatest } from 'redux-saga/effects';

import { appSaga } from './app';
import { authenticationSaga } from './authentication';

import * as t from '../types';

/**
 * combined collection os actionable sagas
 */

export const sagas = function* () {
    yield takeLatest(t.APP_INIT, appSaga);

    yield takeLatest(t.AUTHENTICATION_UPDATE, authenticationSaga);
    yield takeLatest(t.AUTHENTICATION_SIGN_ON, authenticationSaga);
    yield takeLatest(t.AUTHENTICATION_SIGN_OFF, authenticationSaga);
    yield takeLatest(t.AUTHENTICATION_INITIALIZE_KEYCLOAK, authenticationSaga);
};
